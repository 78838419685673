import { EdrHostContainmentState } from '../services/cyber-api';

export class EdrContainmentStateValueConverter {
    private toView(value: string): string {
        if (!value) return value;

        // As we use different display names for the statuses we are supplied with, convert them
        switch (value.toLowerCase()) {
            case EdrHostContainmentState.Normal.toLowerCase():
                return 'Normal';
            case EdrHostContainmentState.Requested.toLowerCase():
                return 'Requested';
            case EdrHostContainmentState.Contain.toLowerCase():
            case EdrHostContainmentState.Containing.toLowerCase():
                return 'Isolating';
            case EdrHostContainmentState.Contain_Fail.toLowerCase():
                return 'Isolation Failed';
            case EdrHostContainmentState.Contained.toLowerCase():
                return 'Isolated';
            case EdrHostContainmentState.Uncontain.toLowerCase():
            case EdrHostContainmentState.Uncontaining.toLowerCase():
                return 'Releasing';
            case EdrHostContainmentState.Uncontaining_Failed.toLowerCase():
                return 'Release Failed';
            default:
                return 'Unknown';
        }
    }
}
